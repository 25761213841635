import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Skills = ({ skills }) => {
  const skillsContainerRef = useRef(null);

  useEffect(() => {
    const setChildPositions = () => {
      const skillGroups = skillsContainerRef.current.querySelectorAll('.skill-group');

      skillGroups.forEach(group => {
        const childSkills = group.querySelectorAll('.child-skill');
        const radius = 130; // adjust as necessary for spacing

        childSkills.forEach((child, index) => {
          const angle = (index / childSkills.length) * (2 * Math.PI);
          const x = radius * Math.cos(angle);
          const y = radius * Math.sin(angle);

          // Adjust for centering within the parent
          child.style.setProperty('--initial-transform', `translate(-50%, -50%)`);
          child.style.setProperty('--hover-transform', `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`);
        });
      });
    };

    setChildPositions();
    window.addEventListener('resize', setChildPositions);

    return () => {
      window.removeEventListener('resize', setChildPositions);
    };
  }, [skills]);

  return (
    <div className="w-full p-8">
      <div className="skills-container" ref={skillsContainerRef}>
        {Object.entries(skills).map(([skill, technologies], index) => (
          <div key={'skill-' + skill.toLowerCase().replaceAll(' ', '-')} className="skill-group">
            {/* Parent Skill Node */}
            <div className="ring-container">
              <div className="skill-ring"></div>
              <div className="parent-skill">
                <span className="text-center text-sm">{skill}</span>
              </div>
            </div>
            {/* Child Skill Nodes */}
            {technologies.map((tech, techIndex) => (
              <div key={'tech-' + tech.toLowerCase().replaceAll(' ', '-')} className="child-skill">
                <span className="text-center text-xs">{tech}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Skills.propTypes = {
  skills: PropTypes.shape({
    skill: PropTypes.array.isRequired,
  }).isRequired
};

export default Skills;
