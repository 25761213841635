const timelineData = [
    {
      "title": "Software Quality Engineer II - JumpCloud",
      "date": "May 2024 - Present",
      "description": "Lead end-to-end quality assurance and automation efforts for identity management products like LDAP, RADIUS, SSO connectors, and SCIM applications, ensuring seamless integrations with Google Workspace, O365, and Microsoft Entra ID."
    },
    {
      "title": "Software Developement Engineer in Test - HackerRank",
      "date": "Jan 2023 - May 2024",
      "description": "Automated UI tests using Ruby Howitzer and Playwright (SerenityJS), while building advanced workflows integrating Slack, AWS Lambda, S3, DynamoDB, and Jenkins to streamline candidate assessment scenarios."
    },
    {
      "title": "System Engineer - Tata Consultancy Services",
      "date": "Apr 2021 - Jan 2023",
      "description": "Spearheaded the development of an automation framework using Python, Selenium, and Robot Framework, reducing execution time by 6.6x and enhancing test parallelization to cut regression runtime by 4.5x."
    },
    {
      "title": "Programmer - Tata Consultancy Services",
      "date": "Feb 2021 - Mar 2021",
      "description": "Completed advanced training in UI automation, learning techniques in XPath building, behavior-driven testing, and optimizing test case execution using the Robot Framework."
    },
    {
      "title": "Graduate Trainee - Tata Consultancy Services",
      "date": "Aug 2020 - Jan 2021",
      "description": "Trained in core IT skills, including web development, version control (Git), agile methodologies, and machine learning fundamentals, providing a foundation for future technical roles."
    },
    {
      "title": "Full-Stack Developer Intern - Scope India Pvt. Ltd.",
      "date": "July 2019 - Jan 2020",
      "description": "Worked as a Full-stack Developer Intern, gaining insights on web application development."
    },
    {
      "title": "Bachelor of Science - Computer Science",
      "date": "Jun 2016 - Mar 2019",
      "description": "Gained strong foundational knowledge in computer science, programming, and data structures, supplemented by practical projects and academic research."
    }
  ];
  
  export default timelineData;
  